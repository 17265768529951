import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import decisionSupportReducer from './decisionSupportReducer'
import envReducer from './envReducer'
import errorReducer from './errorReducer'
import featureReducer from './featureReducer'
import quickQuoteReducer from './quickQuoteReducer'
import quoteReducer from './quoteReducer'
import raterReducer from './raterReducer'

const createRootReducer = (history) =>
  combineReducers({
    env: envReducer,
    quote: quoteReducer,
    error: errorReducer,
    features: featureReducer,
    decisionSupport: decisionSupportReducer,
    quickQuote: quickQuoteReducer,
    rater: raterReducer,
    router: connectRouter(history),
    form: reduxFormReducer,
  })

export default createRootReducer
