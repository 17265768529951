import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import './Loading.scss'

const CustomCircularProgress = () => (
  <CircularProgress id="loadingSpinner" size={120} className="mainLoader" />
)

export default function Loading({ isFetching }) {
  return isFetching ? <CustomCircularProgress /> : null
}

Loading.propTypes = {
  isFetching: PropTypes.bool,
}

Loading.defaultProps = {
  isFetching: false,
}
