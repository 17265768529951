import { RECEIVE_ERROR, CLEAR_ERROR } from '../../actions/errorActions'

const initialState = {
  error: null,
}

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case CLEAR_ERROR:
      return { ...state, error: null }
    default:
      return { ...state }
  }
}

export default errorReducer
