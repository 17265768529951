import {
  REQUEST_QUICK_QUOTE,
  RECEIVE_QUICK_QUOTE,
  RECEIVE_QUICK_QUOTE_ERROR,
  REQUEST_QUICK_QUOTE_CONFIG,
  RECEIVE_QUICK_QUOTE_CONFIG,
  RECEIVE_QUICK_QUOTE_CONFIG_ERROR,
} from '../../actions/quickQuoteActions'

// NOTE:  Once the request is sent, there's no way to re-send the request.
//        This is intentional and expected.

const initialState = {
  quickQuoteConfiguration: [],
  crossSellOpportunities: [],
  configRequestSent: false,
  requestSent: false,
}

const quickQuoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_QUICK_QUOTE:
      return {
        ...state,
        crossSellOpportunities: [],
        requestSent: true,
      }
    case RECEIVE_QUICK_QUOTE:
      return {
        ...state,
        crossSellOpportunities: action.payload,
      }
    case RECEIVE_QUICK_QUOTE_ERROR:
      return {
        ...state,
        crossSellOpportunities: [],
      }
    case REQUEST_QUICK_QUOTE_CONFIG:
      return {
        ...state,
        quickQuoteConfiguration: [],
        configRequestSent: true,
      }
    case RECEIVE_QUICK_QUOTE_CONFIG:
      return {
        ...state,
        quickQuoteConfiguration: action.payload,
      }
    case RECEIVE_QUICK_QUOTE_CONFIG_ERROR:
      return {
        ...state,
        quickQuoteConfiguration: [],
      }
    default:
      return state
  }
}

export default quickQuoteReducer
