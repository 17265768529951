import { createSelector } from 'reselect'
import { isEligibleForRaterSelector } from '../utils/feature-utils'
import { getChannelCode, getJurisdictionCode } from './displayFeature'

const selectDataStatus = (received, fetching) => received && !fetching

const getReceivedRaterUrl = (state) => state?.rater?.receivedRaterUrl
const getIsFetchingRaterUrl = (state) => state?.rater?.isFetchingRaterUrl
const getCcpaAccepted = (state) => state?.rater?.ccpaAccepted
const getReceivedQuoteData = (state) => state?.quote?.receivedQuoteData
const getIsFetchingQuoteData = (state) => state?.quote?.isFetchingQuote
const getReceivedCustomerData = (state) => state?.quote?.receivedCustomerData
const getIsFetchingCustomerData = (state) => state?.quote?.isFetchingCustomer

const selectQuoteStatus = createSelector(
  getReceivedQuoteData,
  getIsFetchingQuoteData,
  selectDataStatus
)

const selectCustomerStatus = createSelector(
  getReceivedCustomerData,
  getIsFetchingCustomerData,
  selectDataStatus
)

const getCanFetchRaterUrl = createSelector(
  selectQuoteStatus,
  selectCustomerStatus,
  getCcpaAccepted,
  (hasQuote, hasCustomer, ccpaAccepted) => {
    const hasData = hasQuote && hasCustomer
    // console.log('getCanFetchRaterUrl', {
    //   hasQuote,
    //   hasCustomer,
    //   ccpaAccepted,
    // })
    return hasData && ccpaAccepted
  }
)

const getRaterUrlIsReady = createSelector(
  getReceivedRaterUrl,
  getIsFetchingRaterUrl,
  (receivedRaterUrl, isFetchingRaterUrl) => {
    // console.log('getRaterUrlIsReady', { receivedRaterUrl, isFetchingRaterUrl })
    return receivedRaterUrl && !isFetchingRaterUrl
  }
)

const AVAILABLE_RATERS = {
  BOLT: 'bolt',
  BOSTON_SOFTWARE: 'bostonSoftware',
}

const getDefaultRater = createSelector(
  getChannelCode,
  getJurisdictionCode,
  (channelCode, jurisdiction) => {
    return isEligibleForRaterSelector(channelCode, jurisdiction)
      ? AVAILABLE_RATERS.BOSTON_SOFTWARE
      : AVAILABLE_RATERS.BOLT
  }
)

export {
  AVAILABLE_RATERS,
  getCanFetchRaterUrl,
  getDefaultRater,
  getRaterUrlIsReady,
}
