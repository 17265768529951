import React from 'react'
import { GridRow } from '@lmig/lmds-react'
import Card from '../Elements/Card'
import './CrossSellOpportunities.scss'
import CrossSellOpportunity from './CrossSellOpportunity'

export const CARD_TITLE = 'Cross Sell Opportunity'
export const CARD_TITLE_PLURAL = 'Cross Sell Opportunities'

const CrossSellOpportunitiesCard = (props) => {
  const { opportunities } = props

  if (!opportunities || opportunities.length < 1) {
    return null
  }

  const cardTitle = opportunities.length > 1 ? CARD_TITLE_PLURAL : CARD_TITLE

  return (
    <Card
      title={cardTitle}
      className="cardCentered"
      id="crossSellOpportunitiesCard"
    >
      <GridRow justify="center" className="crossSellOpportunities" gutters>
        {opportunities.map((opportunity) => (
          <CrossSellOpportunity key={opportunity} opportunity={opportunity} />
        ))}
      </GridRow>
    </Card>
  )
}

export default CrossSellOpportunitiesCard
