import { createSelector } from 'reselect'
import { getQuote, getPrimaryNNumber } from './customer'
import { getProducerFromQuote } from '../utils/quote-utils'
import { trimToEmpty } from '../utils/data-utils'

const getRepInfo = createSelector(
  getQuote,
  getPrimaryNNumber,
  (quote, nNumber) => {
    // Get the rep info from the quote
    const info = getProducerFromQuote(quote)
    // If the chosen nNumber doesn't match the quote, don't return the rep info from the quote
    // This can occur if there's a jobCode match for the CAT team scenario, which would set the
    // primaryNNumber to the value from the URL, which is different than the value in the quote
    if (info && info.PIN && trimToEmpty(info.PIN) === nNumber) {
      return info
    }
  }
)

export { getRepInfo }
