import { datadogRum } from '@datadog/browser-rum'

/**
 * Must match environment used for APM.
 * @returns {string}
 */
const getDatadogEnvFromHostname = () => {
  const { hostname } = window.location
  if (hostname.split('-').length === 1 && hostname !== 'localhost') {
    return 'production'
  }
  if (hostname.startsWith('dev')) {
    return 'development'
  }
  if (hostname.startsWith('test')) {
    return 'test'
  }

  return 'local'
}

const isDeployedEnvironment = (env) => {
  return env !== 'local'
}

/**
 * Setup Datadog Real User Monitoring
 */
const initDatadogRum = () => {
  const datadogEnv = getDatadogEnvFromHostname()

  // Need an environment to setup datadog RUM. Environment must not be local.
  if (datadogEnv && !isDeployedEnvironment(datadogEnv)) {
    return
  }
  datadogRum.setGlobalContextProperty(
    'troux_uuid',
    '081AF6E6-999C-4A5F-BC6B-1E915661EA9B'
  )

  datadogRum.init({
    applicationId: 'ca08e1ea-327e-43ad-adea-432b07c1e96d',
    clientToken: 'pub417c90738296e32e5ebe38bc42aa066b',
    site: 'datadoghq.com',
    service: 'choice-quote-ui-frontend-nodejs',
    env: datadogEnv,
    // Optional: Specify a version number to identify the deployed version of your application in Datadog
    // version: can't use process.env.DD_VERSION
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
  })
}

export { initDatadogRum }
