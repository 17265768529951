import React, { useCallback, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { triggerError } from '../../actions/errorActions'
import {
  availableRaters,
  fetchRaterUrl,
  openUrlInCurrentBrowser,
  redirectToRater,
  updateSelectedRater,
} from '../../actions/raterActions'
import { selectDisplayRaterSelector } from '../../selectors/displayFeature'
import {
  getCanFetchRaterUrl,
  getDefaultRater,
  getRaterUrlIsReady,
} from '../../selectors/rater'
import RaterSelectorCard from './RaterSelectorCard'

const RaterSelector = () => {
  const dispatch = useDispatch()
  const defaultRater = useSelector(getDefaultRater)
  const displayRaterSelector = useSelector(selectDisplayRaterSelector)
  const { sentUserToRater, selectedRater, raterBridgeInfo, transactionId } =
    useSelector(({ env, rater }) => ({
      transactionId: env.TRANSACTION_ID,
      raterBridgeInfo: rater.raterBridgeInfo,
      selectedRater: rater.selectedRater,
      sentUserToRater: rater.sentUserToRater,
    }))
  const canFetchRaterUrl = useSelector(getCanFetchRaterUrl)
  const hasRaterUrl = useSelector(getRaterUrlIsReady)

  const updateAndFetchRater = useCallback(
    async function updateAndFetchRater(rater) {
      dispatch(updateSelectedRater(rater))
      if (canFetchRaterUrl) {
        try {
          await dispatch(fetchRaterUrl())
        } catch (error) {
          dispatch(triggerError(error))
        }
      }
    },
    [canFetchRaterUrl, dispatch]
  )

  const openRater = useCallback(
    function openRater() {
      const raterWindowName = `${selectedRater}-${transactionId}`
      dispatch(redirectToRater(raterWindowName))
      openUrlInCurrentBrowser(raterBridgeInfo.redirectUrl, raterWindowName)
    },
    [selectedRater, transactionId, raterBridgeInfo, dispatch]
  )

  useEffect(() => {
    if (!selectedRater) {
      updateAndFetchRater(defaultRater)
    }
  }, [selectedRater, defaultRater, updateAndFetchRater])

  if (displayRaterSelector) {
    return (
      <RaterSelectorCard
        defaultRater={defaultRater}
        raters={availableRaters}
        showOpenButton={sentUserToRater}
        openButtonDisabled={!hasRaterUrl}
        updateSelectedRater={updateAndFetchRater}
        openSelectedRater={openRater}
      />
    )
  }

  return null
}

export default RaterSelector
