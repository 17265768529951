import axios from 'axios'
import axiosRetry from 'axios-retry'

export const NOTIFY_STATUS = 'NOTIFY_STATUS'
export const MAX_NUM_RETRIES = 3

export function notifyRetryStatus(message) {
  return {
    type: NOTIFY_STATUS,
    message,
  }
}

export const UINotifyObj = {
  dispatch: null,
  currentRetryCount: 0,
  maxRetriesHit: false,
  notifyUI(message) {
    const actionFuncVal = notifyRetryStatus(message)
    if (this.dispatch) {
      return this.dispatch(actionFuncVal)
    }
    console.log(`Dispatch function missing! ${message}`)
  },
  reset() {
    this.dispatch = null
    this.currentRetryCount = 0
    this.maxRetriesHit = false
  },
}

axiosRetry(axios, {
  retries: MAX_NUM_RETRIES,
  retryDelay: (retryCount) => {
    return retryCount * 2000
  },

  retryCondition: (error) => {
    if (error.response.status >= 500) {
      UINotifyObj.currentRetryCount += 1
      if (UINotifyObj.currentRetryCount > MAX_NUM_RETRIES) {
        UINotifyObj.maxRetriesHit = true
        console.log(
          `Max retries reached! ${UINotifyObj.currentRetryCount} > ${MAX_NUM_RETRIES}`
        )
        // Ensure all the settings are reset
        UINotifyObj.reset()
        return false
      }
      UINotifyObj.notifyUI(
        'Sorry this is taking so long. Please continue to wait ...'
      )
      // Update the retryNum param
      // eslint-disable-next-line no-param-reassign
      error.config.params.retryNum = UINotifyObj.currentRetryCount
    }
    console.log(`Retrying ... attempt #${UINotifyObj.currentRetryCount}`)
    return true
  },
})
