import React from 'react'
import { ConnectedRouter } from 'connected-react-router'

import { ChoiceMuiThemeProvider } from '@lmig/choice-react-component-library'
import history from '../helpers/history'
import Routes from './routes'

import { Header } from '../components/Header/Header'
import { Footer } from '../components/Footer/Footer'
import { Feedback } from '../components/Feedback/Feedback'

export default function QuoteUIRouter() {
  return (
    <ConnectedRouter history={history}>
      <ChoiceMuiThemeProvider>
        <div className="content">
          <Header />
          <Routes />
          <Feedback />
          <Footer />
        </div>
      </ChoiceMuiThemeProvider>
    </ConnectedRouter>
  )
}
