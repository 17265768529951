import React, { useEffect } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { Content, GridRow, GridCol, BodyText } from '@lmig/lmds-react'
import Card from '../Elements/Card'

const NoMatch = () => {
  const location = useLocation()
  const match = useRouteMatch()

  useEffect(() => {
    console.error(
      'Routing failed and no match was found!',
      JSON.stringify({ location, match })
    )
  }, [location, match])

  return (
    <Content>
      <Card>
        <GridRow justify="center">
          <GridCol base="flex-initial">
            <BodyText type="article">
              <p>
                Whoops. The page you are looking for does not exist. If you keep
                seeing this contact support.
              </p>
            </BodyText>
          </GridCol>
        </GridRow>
      </Card>
    </Content>
  )
}

export default NoMatch
