import { NOTIFY_STATUS } from '../../actions/axiosWithRetry'
import {
  CCPA_ACCEPTED,
  RECEIVE_RATER_RESPONSE,
  REDIRECT_TO_RATER,
  REQUEST_RATER_INFO,
  UPDATE_SELECTED_RATER,
} from '../../actions/raterActions'

const initialState = {
  selectedRater: '',
  raterBridgeInfo: {
    redirectUrl: '',
    choiceId: null,
    choiceCustomerId: null,
    raterQuoteIds: {
      raterQuoteId: null,
      raterCustomerId: null,
      raterFriendlyId: null,
    },
    clientOrganization: null,
    clientApplication: null,
    rater: null,
    raterInstance: null,
  },
  isFetchingRaterUrl: false,
  receivedRaterUrl: false,
  // Only set to true the first time user opens a rater window
  sentUserToRater: false,
  ccpaAccepted: false,
  ccpaVisible: true,
  message: 'Gathering data and opening comparative rater',
}

const raterReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_RATER:
      return {
        ...state,
        selectedRater: action.rater,
      }
    case REQUEST_RATER_INFO:
      return {
        ...state,
        isFetchingRaterUrl: true,
        receivedRaterUrl: false,
      }
    case RECEIVE_RATER_RESPONSE:
      return {
        ...state,
        raterBridgeInfo: { ...action.payload },
        isFetchingRaterUrl: false,
        receivedRaterUrl: true,
      }
    case REDIRECT_TO_RATER:
      return {
        ...state,
        sentUserToRater: true,
      }
    case CCPA_ACCEPTED:
      return {
        ...state,
        ccpaVisible: false,
        ccpaAccepted: true,
      }
    case NOTIFY_STATUS:
      return {
        ...state,
        message: action.message,
      }
    default:
      return { ...state }
  }
}

export default raterReducer
