import axios from 'axios'
import { selectDisplayRaterSelector } from '../selectors/displayFeature'
import { AVAILABLE_RATERS, getDefaultRater } from '../selectors/rater'
import { getHeaders } from '../utils/header-utils'
import { sendLogMessage } from './logActions'

export const UPDATE_SELECTED_RATER = 'UPDATE_SELECTED_RATER'
export const REQUEST_RATER_INFO = 'REQUEST_RATER_INFO'
export const RECEIVE_RATER_RESPONSE = 'RECEIVE_RATER_RESPONSE'
export const REDIRECT_TO_RATER = 'REDIRECT_TO_RATER'
export const CCPA_ACCEPTED = 'CCPA_ACCEPTED'

export const availableRaters = [
  {
    displayName: 'Bolt X',
    name: AVAILABLE_RATERS.BOLT,
  },
  {
    displayName: 'Boston Software',
    name: AVAILABLE_RATERS.BOSTON_SOFTWARE,
  },
]

export function requestRaterBridgeInfo() {
  return {
    type: REQUEST_RATER_INFO,
  }
}

export function receiveRaterBridgeResponse(responseData) {
  return {
    type: RECEIVE_RATER_RESPONSE,
    payload: responseData,
  }
}

export const updateSelectedRater = (rater) => {
  return {
    type: UPDATE_SELECTED_RATER,
    rater,
  }
}

export const fetchRaterUrl = () => {
  return async (dispatch, getState) => {
    // Ensure that a default rater is selected if the rater control is not available
    const canDisplayRaterSelector = selectDisplayRaterSelector(getState())
    if (!canDisplayRaterSelector) {
      const defaultRater = getDefaultRater(getState())
      console.log(
        `Rater selector is not available. Defaulting to ${defaultRater}.`
      )
      dispatch(updateSelectedRater(defaultRater))
    }
    dispatch(requestRaterBridgeInfo())

    const {
      env: { TRANSACTION_ID: transactionId },
      quote: { lineOfBusiness },
      rater: { selectedRater },
      router: {
        location: {
          query: {
            agreementId,
            customerId,
            env,
            subEnv,
            channelCode,
            nNumber,
            affinityClient,
            drivenIndicator,
          },
        },
      },
    } = getState()

    const url = `${process.env.API_BASE}/comparativeRater/getBridgeInfo`

    const headers = getHeaders({ env, subEnv, transactionId })

    const params = {
      selectedRater,
      agreementId,
      customerId,
      lineOfBusiness,
      env,
      subEnv,
      affinityClient,
      drivenIndicator,
      channelCode,
      nNumber,
    }

    try {
      const request = axios.create()
      const response = await request.get(url, { headers, params })
      console.log(`Rater URL: ${response.data.redirectUrl}`)
      dispatch(receiveRaterBridgeResponse(response.data))
    } catch (error) {
      if (error.response && error.response.status.toString() === '500') {
        error.message = '500 Internal Server Error'
      }

      console.log('/getBridgeInfo returned an error:', error)
      throw error
    }
  }
}

export const redirectToRater = (raterWindowName) => {
  return {
    type: REDIRECT_TO_RATER,
    sentUserToRater: true,
    raterWindowName,
  }
}

export const openUrlInCurrentBrowser = (url, windowName = 'rater-window') => {
  console.log('Opening rater window', url, windowName)
  window.open(
    url,
    windowName,
    'scrollbars=1,resizable=1,location=1,status=1,toolbar=1,titlebar=1'
  )
}

export const ccpaAccepted = (values) => (dispatch) => {
  dispatch(sendLogMessage('CCPA Accepted', values))
  dispatch({
    type: CCPA_ACCEPTED,
  })
}

// The function parameter and if block can be removed, as QUI does not use iframes
export const startRaterBridge = (window) => async (dispatch, getState) => {
  const {
    env: { TRANSACTION_ID: transactionId },
    rater: { selectedRater, raterBridgeInfo },
  } = getState()
  if (window) {
    // Create a unique window name
    const windowName = `${selectedRater}-${transactionId}`

    dispatch(
      sendLogMessage(
        `Bridging user to ${selectedRater}: ${raterBridgeInfo.redirectUrl}`,
        {
          raterWindowName: windowName,
        }
      )
    )
    openUrlInCurrentBrowser(raterBridgeInfo.redirectUrl, windowName)

    // Let the UI know we've sent the user to rater and it's safe to show the AMS Messaging
    dispatch(redirectToRater(windowName))
  }
}
