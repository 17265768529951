import React from 'react'
import { useSelector } from 'react-redux'
import { Header as LibraryHeader } from '@lmig/choice-react-component-library'
import { getCustomerInfo } from '../../selectors/customer'

export const TITLE = 'Choice Quote and Bind'
export const Header = () => {
  const customerInfo = useSelector(getCustomerInfo)
  const headerCustomerInfo = {
    customerLastName: customerInfo?.lastName,
    customerCity: customerInfo?.city,
    customerJurisdiction: customerInfo?.state,
    customerZipCode: customerInfo?.zip,
  }
  return (
    <LibraryHeader
      sticky
      logo={null}
      title={TITLE}
      showCustomerInfo
      customerInfo={headerCustomerInfo}
    />
  )
}
