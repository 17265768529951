import axios from 'axios'
import { getHeaders } from '../utils/header-utils'
import {
  getPrimaryNNumber,
  getSpecificLineOfBusiness,
} from '../utils/quote-utils'
import { sendLogMessage } from './logActions'

export const REQUEST_QUOTE_DATA = 'REQUEST_QUOTE_DATA'
export const RECEIVE_QUOTE_DATA = 'RECEIVE_QUOTE_DATA'
export const UPDATE_LINE_OF_BUSINESS = 'UPDATE_LINE_OF_BUSINESS'
export const REQUEST_CUSTOMER_DATA = 'REQUEST_CUSTOMER_DATA'
export const RECEIVE_CUSTOMER_DATA = 'RECEIVE_CUSTOMER_DATA'
export const SET_PRIMARY_NNUMBER = 'SET_PRIMARY_NNUMBER'

export function setPrimaryNNumber(data) {
  return {
    type: SET_PRIMARY_NNUMBER,
    data,
  }
}

export function requestQuoteData(lob, agreementId, nNumber) {
  console.log(
    `Requesting ${lob} quote data for agreement ID ${agreementId} and nNumber ${nNumber}`
  )
  return {
    type: REQUEST_QUOTE_DATA,
    payload: {
      lob,
      agreementId,
    },
  }
}

export function receiveQuoteData(data) {
  console.log('Received quote data response')
  return {
    type: RECEIVE_QUOTE_DATA,
    data,
  }
}

export function updateLineOfBusiness(quoteData, queryLineOfBusiness) {
  return {
    type: UPDATE_LINE_OF_BUSINESS,
    payload: getSpecificLineOfBusiness(quoteData, queryLineOfBusiness),
  }
}

export function requestCustomerData(customerId, nNumber) {
  console.log(
    `Requesting customer data for customer ID ${customerId} and nNumber ${nNumber}`
  )
  return {
    type: REQUEST_CUSTOMER_DATA,
    payload: {
      customerId,
    },
  }
}

export function receiveCustomerData(data) {
  console.log('Received customer data response')
  return {
    type: RECEIVE_CUSTOMER_DATA,
    data,
  }
}

export const fetchQuoteData = () => {
  return async function getQuoteData(dispatch, getState) {
    const {
      env: { TRANSACTION_ID: transactionId },
      router: {
        location: {
          query: {
            agreementId,
            env = 'production',
            subEnv,
            lob,
            nNumber,
            customerId,
            jurisdiction,
            jobCode,
          },
        },
      },
    } = getState()
    const url = `${process.env.API_BASE}/getQuote/${agreementId}/${lob}/${nNumber}`
    const headers = getHeaders({ env, subEnv, transactionId })

    dispatch(requestQuoteData(lob, agreementId, nNumber))

    // Create a unique axios object, since we don't want to retry on errors
    const request = axios.create()

    // Call our endpoint
    try {
      const response = await request.get(url, {
        headers,
      })
      // Update Line of Business
      dispatch(updateLineOfBusiness(response.data, lob))
      // Determine which nNumber to use
      const nNumberResult = getPrimaryNNumber(response.data, nNumber, jobCode)
      dispatch(setPrimaryNNumber(nNumberResult.primaryNNumber))
      // Notify the rest of the app that we have quote data
      dispatch(receiveQuoteData(response.data))
      dispatch(sendLogMessage('Set primary nNumber', nNumberResult))
      dispatch(
        sendLogMessage(`Bind on Behalf Of tracking data`, {
          nNumberResult,
          agreementId,
          lineOfBusiness: lob,
          customerId,
          jurisdiction,
        })
      )
    } catch (error) {
      // TODO: Improve this - possibly use `trimError` from `errorActions`
      if (error.response && error.response.status === 500) {
        error.message = '500 Internal Server Error fetching Quote Data'
      }
      throw error
    }
  }
}

export const fetchCustomerData = () => {
  return async function getCustomerData(dispatch, getState) {
    const {
      env: { TRANSACTION_ID: transactionId },
      router: {
        location: {
          query: { customerId, env = 'production', nNumber, subEnv },
        },
      },
    } = getState()
    // Generate our URL
    const url = `${process.env.API_BASE}/getCustomer/${customerId}/${nNumber}`
    const headers = getHeaders({ env, subEnv, transactionId })

    dispatch(requestCustomerData(customerId, nNumber))
    // Create a unique axios object, since we don't want to retry on errors
    const request = axios.create()

    // Call our endpoint
    try {
      const response = await request.get(url, {
        headers,
      })
      dispatch(receiveCustomerData(response.data))
    } catch (error) {
      if (error.response && error.response.status === 500) {
        error.message = '500 Internal Server Error fetching Customer Data'
      }
      throw error
    }
  }
}
