import React from 'react'
import { GridRow, GridCol, Heading } from '@lmig/lmds-react'
import { IconSuccessAlert, IconInfo } from '@lmig/lmds-react/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '../Elements/Card'

import './ConversionChance.scss'

// The Icon components need this to be a string
const ICON_SIZE = '64'

export const ConversionChanceStateNames = {
  NONE: 'NONE',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  LIBERTY: 'LIBERTY',
  CHOICE: 'CHOICE',
  UNDERWRITING_REJECT: 'UNDERWRITING_REJECT',
}

const loadingState = {
  // The size parameter is supposed to support both String and Number values, but it only seems to work as a number
  icon: (
    <CircularProgress
      id="conversionChanceLoadingSpinner"
      size={parseInt(ICON_SIZE, 10)}
      aria-label="Loading Spinner"
    />
  ),
  message: 'Calculating conversion rates...',
}
const libertyPreferredState = {
  icon: <IconInfo size={ICON_SIZE} />,
  message: 'Offer Choice, options help you win!',
}
export const ConversionChanceStates = {
  // The NONE state is set when the user is in the Control group. The component won't render, but we need this for logging
  [ConversionChanceStateNames.NONE]: loadingState,
  [ConversionChanceStateNames.LOADING]: loadingState,
  [ConversionChanceStateNames.CHOICE]: {
    icon: <IconSuccessAlert size={ICON_SIZE} color="positive" />,
    message: 'This customer is likely to convert with Choice!',
  },
  [ConversionChanceStateNames.UNDERWRITING_REJECT]: {
    icon: <IconSuccessAlert size={ICON_SIZE} color="positive" />,
    message:
      'The Liberty quote was rejected due to underwriting, so let\u2019s pivot to Choice to find a solution!',
  },

  // Ensure ERROR state is identical to LIBERTY.
  // We need the ERROR state to be distinct, for tracking & debugging purposes.
  [ConversionChanceStateNames.LIBERTY]: libertyPreferredState,
  [ConversionChanceStateNames.ERROR]: libertyPreferredState,
}

const ConversionChance = (props) => {
  const { state } = props
  return (
    <Card className="conversionChanceBase" id="conversionChance">
      <GridRow justify="center">
        <GridCol base="flex-initial" className="centeredContainer">
          {ConversionChanceStates[state].icon}
        </GridCol>
        <GridCol base={10}>
          <Heading type="h3-light" align="left">
            {ConversionChanceStates[state].message}
          </Heading>
        </GridCol>
      </GridRow>
    </Card>
  )
}

export default ConversionChance
