import dedent from 'dedent'
import querystring from 'querystring'
import history from '../helpers/history'

export const RECEIVE_ERROR = 'RECEIVE_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export function trimError(error = {}, message) {
  const cleanError = {
    message: message || error.message || '',
    status: 500,
    detail: '',
  }
  if (error.response) {
    const {
      data,
      status,
      config: { url },
      request: { responseURL },
    } = error.response
    // eslint-disable-next-line no-param-reassign
    cleanError.detail = dedent`
      Data: ${JSON.stringify(data)}
      URL: ${responseURL || url}`
    cleanError.url = responseURL || url
    cleanError.data = data
    cleanError.status = status
  }
  return cleanError
}

export function receiveError(error) {
  const cleanError = trimError(error)
  return {
    type: RECEIVE_ERROR,
    error: cleanError,
  }
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  }
}

export function triggerError(error, redirect = true) {
  return async function errorTrigger(dispatch, getState) {
    dispatch(clearError())
    dispatch(receiveError(error))
    if (redirect) {
      const {
        router: {
          location: { query: params },
        },
      } = getState()
      history.push(`error?${querystring.stringify(params)}`)
    }
  }
}
