import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchQuickQuote,
  receiveQuickQuoteError,
} from '../../actions/quickQuoteActions'
import CrossSellOpportunitiesCard from './CrossSellOpportunitiesCard'
import { sendLogMessage } from '../../actions/logActions'

const QuickQuote = () => {
  const dispatch = useDispatch()

  const {
    quickQuoteEnabled,
    crossSellOpportunities,
    quickQuoteRequestSent,
    receivedQuoteData,
  } = useSelector(({ quickQuote, features, quote }) => ({
    quickQuoteEnabled: features.features.quickQuote,
    receivedQuoteData: quote.receivedQuoteData,
    quickQuoteRequestSent: quickQuote.requestSent,
    crossSellOpportunities: quickQuote.crossSellOpportunities,
  }))

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchQuickQuote())
      } catch (error) {
        dispatch(receiveQuickQuoteError(error.message))
        sendLogMessage("Error processing 'fetchQuickQuote'", error)
      }
    }

    if (quickQuoteEnabled && !quickQuoteRequestSent && receivedQuoteData) {
      fetchData()
    }
  }, [dispatch, quickQuoteEnabled, receivedQuoteData, quickQuoteRequestSent])

  if (!quickQuoteEnabled) {
    return null
  }

  return <CrossSellOpportunitiesCard opportunities={crossSellOpportunities} />
}

export default QuickQuote
