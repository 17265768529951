import compareDesc from 'date-fns/compareDesc'
import parseISO from 'date-fns/parseISO'
import { ensureIsArray, trimToEmpty } from './data-utils'

const getMostRecentProducerEvent = (producerEvents = []) => {
  if (producerEvents.length) {
    producerEvents.sort((a, b) => {
      return compareDesc(
        parseISO(a.DateTimeCreated),
        parseISO(b.DateTimeCreated)
      )
    })
    return producerEvents[0]
  }

  return {}
}

// TODO: Combine this and `getChannelCode` and `getProducerFromQuote`
/**
 * @param {Object} quote The full Quote data
 * @returns Rep info in the form of a ProducerEvent, if available. Otherwise, undefined.
 */
const getProducerFromQuote = (quote = {}) => {
  const repInfo = getMostRecentProducerEvent(ensureIsArray(quote.ProducerEvent))
  if (Object.keys(repInfo).length) {
    return repInfo
  }
}

const getNamedInsured = (
  namedInsuredNumber = 1,
  quote = {},
  householdMemberList = [],
  lob = ''
) => {
  // Find first named insured and get their customer id
  const person =
    lob === 'home'
      ? ensureIsArray(quote.CustomerAgreement).find(
          (customer) => customer.NamedInsuredNumber === namedInsuredNumber
        )
      : ensureIsArray(quote.Operator).find(
          (operator) => operator.OperatorNumber === namedInsuredNumber
        )

  // Could not find customer, return null
  if (!person) {
    return null
  }

  // Find and return household based on customer id
  return ensureIsArray(householdMemberList).find(
    (householdMember) => householdMember.ID === person.HouseholdMemberID
  )
}

/**
 * @description Finds the residential phone number if it exists. Otherwise returns the mobile phone number.
 *  Return phone type with value. Return empty array if none exist.
 * @param {Object} customer The customer object (houseHoldMember)
 * @returns {Array[string, string]} Phone number and type ('RES', 'MOBILE')
 */
const getPhoneNumber = (customer) => {
  if (!customer.ContactInformation || !customer.ContactInformation.Phone) {
    return []
  }
  const phoneNumbers = ensureIsArray(customer.ContactInformation.Phone)
  // Get both phone number options, either could return `undefined`
  const residentialPhone = phoneNumbers.find(
    (phone) => phone.PhoneTypeCode === 'RES'
  )
  const mobilePhone = phoneNumbers.find(
    (phone) => phone.PhoneTypeCode === 'MOBILE'
  )

  // Favor the residential phone number
  const phoneNumber = residentialPhone || mobilePhone
  // Return phone number type along with value, or return an empty array
  return phoneNumber ? [phoneNumber.PhoneNumber, phoneNumber.PhoneTypeCode] : []
}

const getEmailAddress = (customer) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  if (
    customer.ContactInformation.Email &&
    customer.ContactInformation.Email.EmailAddress &&
    emailRegex.test(customer.ContactInformation.Email.EmailAddress)
  ) {
    return customer.ContactInformation.Email.EmailAddress
  }
  return null
}

const getPrimaryAddress = (customer) => {
  return ensureIsArray(customer.ContactInformation.ContactAddressType).find(
    (contactAddressType) =>
      contactAddressType.AddressTypeDescription === 'PRIMARY RESIDENTIAL'
  ).Address
}

// TODO: This isn't used anymore and can be removed
const createCustomerEventData = (firstNamedInsured, customerId) => {
  const address = getPrimaryAddress(firstNamedInsured)
  const [phone = ''] = getPhoneNumber(firstNamedInsured)
  return {
    id: trimToEmpty(customerId),
    dayPhone: String(phone),
    firstName: trimToEmpty(firstNamedInsured.FirstName),
    lastName: trimToEmpty(firstNamedInsured.LastName),
    state: trimToEmpty(address.JurisdictionCode),
    zip: trimToEmpty(address.ZipCode),
    addrLine1: trimToEmpty(address.StreetAddressDescription),
    languagePreference: trimToEmpty(firstNamedInsured.PreferredLanguageCode),
  }
}

/**
 *
 * @param {Object} report The report to process
 *
 * @description
 * This is a helper function to extract relevant details from a report.
 */
const getReportDetails = (report) => {
  const {
    ID: id,
    ReportVendorTypeCode,
    ReportProcessingStatusCode,
    ReportDateOrdered,
    ReportOrderNumber,
  } = report
  return {
    id,
    ReportVendorTypeCode,
    ReportProcessingStatusCode,
    ReportDateOrdered,
    ReportOrderNumber,
  }
}

/**
 *
 * @param {Object} quote The full Quote data from the client
 * @returns {Object} Return the reports embedded in the quote data as an object whose keys are the report type,
 and values are an array of reports of that type with the following properties
 ```
 {
    id,
    ReportVendorTypeCode,
    ReportProcessingStatusCode,
    ReportDateOrdered,
    ReportOrderNumber,
  }
 ```
 * @description
 * The reports are stored in two possible places, as a top-level `ExternalReport` object,
 * and as an array of `ExternalReport` objects inside the `Operator` element, which can be
 * an array or an object.
 */
const getReports = (quote) => {
  const reports = {}

  const quoteReports = ensureIsArray(quote.ExternalReport)
  const operators = ensureIsArray(quote.Operator)
  // Status codes to skip - these indicate the report has not been run
  const invalidStatusCodes = ['NO', 'NA']
  const processReport = (report) => {
    // We're only interested in reports that have actually been run
    if (invalidStatusCodes.indexOf(report.ReportProcessingStatusCode) === -1) {
      if (!reports[report.ReportTypeCode]) {
        reports[report.ReportTypeCode] = []
      }
      reports[report.ReportTypeCode].push(getReportDetails(report))
    }
  }

  quoteReports.forEach(processReport)
  operators.forEach((operator) =>
    ensureIsArray(operator.ExternalReport).forEach(processReport)
  )
  return reports
}

const getClientNumber = (quote, customer) => {
  if (quote.AffinityID && customer.affinityList) {
    const selectedAffinity = ensureIsArray(customer.affinityList).find(
      (affinity) => affinity.ID === quote.AffinityID
    )
    return (selectedAffinity && selectedAffinity.ClientNumber) || ''
  }
  return ''
}

/**
 * Ensures producer event exists and returns producer n number & channel code
 * @param {ProducerEvent} producerEvent
 */
const getProducerDataFromEvent = (producerEvent) => {
  const producerData = {
    channelCode: undefined,
    nNumber: undefined,
  }
  if (producerEvent) {
    producerData.nNumber = producerEvent.PIN
      ? producerEvent.PIN.trim().toUpperCase()
      : undefined
    producerData.channelCode = producerEvent.DistributionChannelCode
      ? producerEvent.DistributionChannelCode.trim()
      : undefined
  }
  return producerData
}

const getProducerChannelCode = (producerEvents) => {
  return getProducerDataFromEvent(getMostRecentProducerEvent(producerEvents))
    .channelCode
}

/**
 *
 * @param {Object} quote The full Quote data from the client
 * @returns {String} the producer number
 * @description
 * Get the nNumber from the producer event with the most recent date
 */
const getProducerNNumber = (quote = {}) => {
  return getProducerDataFromEvent(getProducerFromQuote(quote)).nNumber
}

/**
 *
 * @param {Object} data The full `quote` object
 * @param {String} nNumber The nNumber from the request URL, which is usually the logged in user
 * @param {String} jobCode The jobCode from the URL to check against
 * @returns {Object} An object (detailed below) with the correct nNumber and a description of why it was selected
 *  ```
 *    primaryNNumber: User who should 'get credit' for binding the quote
 *    nNumberFromUrl: User who is logged in and actively quoting. Can be a producer or non-producer,
 *    producerNNumber: Producer who assigned to the quote,
 *    reason: why the Primary N Number is the primary in this case,
 *    jobCode: job code associated with logged in user
 *  ```
 */
const getPrimaryNNumber = (data, nNumberFromUrl, jobCode) => {
  const { quote } = data
  const producerData = getProducerDataFromEvent(getProducerFromQuote(quote))
  const nNumbers = {
    primaryNNumber: null,
    nNumberFromUrl,
    producerNNumber: producerData.nNumber,
    producerChannelCode: producerData.channelCode,
    reason: '',
    jobCode,
  }

  // ToDo: move to config and/or dashboard
  const jobCodes = [
    '6515',
    '7050',
    '7052',
    '1121',
    '3949',
    '2002',
    '6514',
    '2001',
    '2003',
  ]
  // Some jobCodes start with a 0 that can be ignored
  // If user is one of the specified job codes, then they should be the primary
  if (jobCode && jobCodes.indexOf(jobCode.replace(/^0/, '')) >= 0) {
    nNumbers.reason =
      'getPrimaryNNumber: jobCode matched, using nNumber from query string'
    nNumbers.primaryNNumber = nNumbers.nNumberFromUrl
  } else if (nNumbers.producerNNumber) {
    // Use the producer N Number if we found one
    nNumbers.reason = 'getPrimaryNNumber: found producerEvent, using PIN'
    nNumbers.primaryNNumber = nNumbers.producerNNumber
  } else {
    // Otherwise, default to the n number from the URL, because we always get one
    nNumbers.reason = 'getPrimaryNNumber: use provided nNumber, default'
    nNumbers.primaryNNumber = nNumbers.nNumberFromUrl
  }
  return nNumbers
}

const LINE_OF_BUSINESS_CODE = {
  HOMEOWNERS: 'H3',
  RENTERS: 'H4',
  CONDO: 'H6',
}

/**
 * Convert a LOB value from CF to a rater-specific value
 *
 * @param {object} quoteData Data from Market Service
 * @param {string} defaultLineOfBusiness LOB value from URL
 * @returns {string} Rater-specific line of business string
 */
const getSpecificLineOfBusiness = (quoteData, defaultLineOfBusiness) => {
  const { quote } = quoteData
  // Only Property quotes use this field, auto quotes do not support specific LOBs in CF
  if (defaultLineOfBusiness === 'auto') {
    return 'PersonalAuto'
  }
  // Translate Property LOBs
  if (quote.PolicySymbolCode) {
    switch (quote.PolicySymbolCode) {
      case LINE_OF_BUSINESS_CODE.HOMEOWNERS:
        return 'PersonalHome'
      case LINE_OF_BUSINESS_CODE.RENTERS:
        return 'Renters'
      case LINE_OF_BUSINESS_CODE.CONDO:
        return 'Condominium'
      default:
        // No match, use what's in the URL
        return defaultLineOfBusiness
    }
  }

  return defaultLineOfBusiness
}

export {
  createCustomerEventData,
  getClientNumber,
  getEmailAddress,
  getNamedInsured,
  getPhoneNumber,
  getPrimaryAddress,
  getPrimaryNNumber,
  getProducerChannelCode,
  getProducerFromQuote,
  getProducerNNumber,
  getReports,
  getSpecificLineOfBusiness,
}
