import { createSelector } from 'reselect'

const getEnvTransactionId = (state) => state.env.TRANSACTION_ID
const getTransactionId = createSelector(
  getEnvTransactionId,
  (transactionId) => {
    if (transactionId) {
      return transactionId
    }
  }
)

export { getTransactionId }
