import axios from 'axios'
import { getHeaders } from '../utils/header-utils'

export const SEND_LOG_MESSAGE = 'SEND_LOG_MESSAGE'

export function notifyLog(message, data) {
  return {
    type: SEND_LOG_MESSAGE,
    payload: {
      message,
      data,
    },
  }
}

export const sendLogMessage = (message, data) => async (dispatch, getState) => {
  dispatch(notifyLog(message, data))
  const {
    env: { TRANSACTION_ID: transactionId },
  } = getState()
  const request = axios.create()
  const url = `${process.env.API_BASE}/log`
  try {
    await request.post(
      url,
      {
        message: `[QuoteUI Frontend]: ${message}`,
        data,
      },
      {
        headers: getHeaders({ transactionId }),
      }
    )
    console.log(`Sent message to logger: ${message}`, data)
  } catch (error) {
    console.error(`Caught error sending log message`, error)
  }
}
