import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FeedbackModal } from '@lmig/choice-react-component-library'
import { sendLogMessage } from '../../actions/logActions'

export const FEEDBACK_PROMPT = 'How is your experience with the Choice Apps?'

export const Feedback = () => {
  const dispatch = useDispatch()

  const { queryParameters } = useSelector(({ router }) => ({
    queryParameters: router.location.query,
  }))

  return (
    <FeedbackModal
      feedbackPrompt={FEEDBACK_PROMPT}
      boxBackgroundColor="white"
      submitted={(feedbackButton, feedbackText) =>
        dispatch(
          sendLogMessage('Feedback provided', {
            feedbackText,
            feedbackButton,
            // This is the entire url the browser is currently at
            location: window.location.href,
            customerId: queryParameters.customerId,
            agreementId: queryParameters.agreementId,
            repNNumber: queryParameters.nNumber,
            feedbackLog: true,
          })
        )
      }
    />
  )
}
