import {
  REQUEST_CONVERSION_CHANCE,
  RECEIVE_CONVERSION_CHANCE,
  LOG_CONTROL_OR_TEST_MESSAGE_SENT,
  LOG_RESULT_MESSAGE_SENT,
} from '../../actions/decisionSupportActions'
import { RECEIVE_FEATURE_ACCESS } from '../../actions/featureActions'
import { ConversionChanceStateNames } from '../../components/DecisionSupport/ConversionChance'
import {
  isEligibleForConversionChance,
  isInConversionChanceControlGroup,
} from '../../utils/feature-utils'

const initialState = {
  isEligible: false,
  isControlGroup: false,
  conversionChanceState: ConversionChanceStateNames.LOADING,
  reason: null,
  requestSent: false,
  logResultMessageSent: false,
  logControlOrTestMessageSent: false,
}

const decisionSupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CONVERSION_CHANCE:
      return {
        ...state,
        conversionChanceState: ConversionChanceStateNames.LOADING,
        requestSent: true,
      }
    case RECEIVE_CONVERSION_CHANCE:
      return {
        ...state,
        conversionChanceState: action.conversionChanceState,
        reason: action.reason,
      }
    case RECEIVE_FEATURE_ACCESS: {
      const isEligible = isEligibleForConversionChance(action.eligibleFeatures)
      const isControlGroup = isInConversionChanceControlGroup(
        action.eligibleFeatures
      )
      let { conversionChanceState } = state
      if (!isEligible || isControlGroup) {
        conversionChanceState = ConversionChanceStateNames.NONE
      }
      return {
        ...state,
        conversionChanceState,
        isEligible,
        isControlGroup,
      }
    }
    case LOG_RESULT_MESSAGE_SENT:
      return {
        ...state,
        logResultMessageSent: true,
      }
    case LOG_CONTROL_OR_TEST_MESSAGE_SENT:
      return {
        ...state,
        logControlOrTestMessageSent: true,
      }
    default:
      return { ...state }
  }
}

export default decisionSupportReducer
