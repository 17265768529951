import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchConversionChance,
  receiveConversionChanceError,
  logDecisionSupportResult,
  logDecisionSupportControlOrTestGroup,
} from '../../actions/decisionSupportActions'
import { sendLogMessage } from '../../actions/logActions'
import ConversionChance from './ConversionChance'

const DecisionSupport = () => {
  const dispatch = useDispatch()

  const {
    isEligible,
    isControlGroup,
    hasDecisionSupport,
    conversionChanceState,
    conversionChanceRequestSent,
    receivedQuoteData,
    receivedCustomerData,
  } = useSelector(({ decisionSupport, features, quote }) => ({
    isEligible: decisionSupport.isEligible,
    isControlGroup: decisionSupport.isControlGroup,
    hasDecisionSupport: features.features.decisionSupport,
    conversionChanceState: decisionSupport.conversionChanceState,
    conversionChanceRequestSent: decisionSupport.requestSent,
    receivedQuoteData: quote.receivedQuoteData,
    receivedCustomerData: quote.receivedCustomerData,
  }))

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(fetchConversionChance())
        // Log the decision support outcome for users in the test group
        // Included here because all the necessary data is available
        dispatch(logDecisionSupportResult())
      } catch (error) {
        dispatch(receiveConversionChanceError(error.message))
        sendLogMessage("Error processing 'fetchConversionChance'", error)
      }
    }

    // Send a request once we have quote and customer info
    if (
      isEligible &&
      hasDecisionSupport &&
      !conversionChanceRequestSent &&
      receivedQuoteData &&
      receivedCustomerData
    ) {
      fetchData()
    } else if (
      hasDecisionSupport &&
      receivedQuoteData &&
      receivedCustomerData
    ) {
      // Log the decision support outcome for users in the control vs test group
      dispatch(logDecisionSupportControlOrTestGroup())
    }
  }, [
    dispatch,
    isEligible,
    isControlGroup,
    hasDecisionSupport,
    receivedQuoteData,
    receivedCustomerData,
    conversionChanceRequestSent,
  ])

  // Don't render anything if decision support isn't available
  // This covers users in the Control group, users explicitly without permission, and feature toggle OFF
  if (!hasDecisionSupport || !isEligible) {
    return null
  }

  return <ConversionChance state={conversionChanceState} />
}

export default DecisionSupport
