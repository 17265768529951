import { AxiosHeaders } from 'axios'

const X_TRANSACTION_ID = 'x-transaction-id'
const X_SUB_ENV = 'x-sub-env'
const X_ENV = 'x-env'

/**
 * What the property for 'env' is mapped to
 * @returns {string}
 */
export const getHeaderPropertyNameForEnv = () => {
  return X_ENV
}

/**
 * What the property for 'subEnv' is mapped to
 * @returns {string}
 */
export const getHeaderPropertyNameForSubEnv = () => {
  return X_SUB_ENV
}

/**
 * What the property for 'transactionId' is mapped to
 * @returns {string}
 */
export const getHeaderPropertyNameForTransactionId = () => {
  return X_TRANSACTION_ID
}

/**
 * Creates an object that can have up to 3 properties set
 * Will only set provided properties
 * Accepted properties: env, subEnv, transactionId
 *
 * @param {{env: string, subEnv: string, transactionId: string}}
 * @returns {object}
 */
export const getHeaders = ({ env, subEnv, transactionId } = {}) => {
  const headers = {}

  if (env) {
    headers[getHeaderPropertyNameForEnv()] = env
  }

  if (subEnv) {
    headers[getHeaderPropertyNameForSubEnv()] = subEnv
  }

  if (transactionId) {
    headers[getHeaderPropertyNameForTransactionId()] = transactionId
  }
  return new AxiosHeaders(headers)
}
