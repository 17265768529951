/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Content,
  Caption,
  Link,
  List,
  ListItem,
  Notification,
} from '@lmig/lmds-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import dedent from 'dedent'
import Card from '../Elements/Card'

const ErrorComponent = () => {
  const { error, transactionId } = useSelector(
    ({ error: errorState, env }) => ({
      error: errorState.error,
      transactionId: env.TRANSACTION_ID,
    })
  )

  const [didCopy, setDidCopy] = useState(false)

  const message = error ? error.message : 'An unknown error has occurred'
  const status = error?.response?.status
  const ts = new Date()
  const detail = dedent`Error: ${message}
Time: ${ts.toISOString()}
Transaction ID: ${transactionId}
${error ? error.detail : ''}`

  let title = ''
  switch (status) {
    case 500:
      title =
        'Unfortunately, something went wrong when trying to process your request. Please close this window and try again from CustomersFirst.'
      break
    default:
      title = 'An error has occured. Please try again.'
      break
  }

  return (
    <Content>
      <Card title={title} className="cardCentered">
        <Notification type="error">
          {message}: {new Date().toLocaleString()}
          <Caption>
            Transaction ID: <strong>{transactionId}</strong>
          </Caption>
          <p>
            If you are seeing this error consistently, please follow the steps
            below to help us troubleshoot the error:
          </p>
          <List compact variant="ordered">
            <ListItem>
              Click{' '}
              <CopyToClipboard text={detail} onCopy={() => setDidCopy(true)}>
                <Link>here</Link>
              </CopyToClipboard>{' '}
              to copy information related to the error
            </ListItem>
            <ListItem>
              Click{' '}
              <Link
                href="https://forge.lmig.com/issues/servicedesk/customer/portal/694/create/14824"
                target="_blank"
              >
                here
              </Link>{' '}
              to open a Choice Service Desk support ticket
            </ListItem>
            <ListItem>
              Paste the error information into the "Additional Info" section of
              the support ticket
            </ListItem>
          </List>
        </Notification>
        {didCopy && (
          <Notification type="informational" dismissible>
            Copied successfully!
          </Notification>
        )}
      </Card>
    </Content>
  )
}

export default ErrorComponent
