import React from 'react'
import { GridCol } from '@lmig/lmds-react'
import {
  IconFlood,
  IconEarthquake,
  IconUmbrella,
  IconPet,
  IconWatercraft,
  IconValuables,
} from '@lmig/lmds-react/icons'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { CROSS_SELL_OPPORTUNITIES } from '../../actions/quickQuoteActions'
import './CrossSellOpportunities.scss'

export const OPPORTUNITY_TOOLTIPS = {
  [CROSS_SELL_OPPORTUNITIES.FLOOD]: 'Customer lives in a flood zone',
  [CROSS_SELL_OPPORTUNITIES.EARTHQUAKE]: 'Customer lives in an earthquake zone',
  [CROSS_SELL_OPPORTUNITIES.UMBRELLA]:
    'Customer is eligible for an umbrella policy',
  [CROSS_SELL_OPPORTUNITIES.PET]: 'Customer has a pet at home',
  [CROSS_SELL_OPPORTUNITIES.JEWELRY]:
    'Customer may have valuable jewelry to insure',
  [CROSS_SELL_OPPORTUNITIES.WATERCRAFT]:
    'Customer indicated they have a watercraft',
}

const LibertyTooltip = withStyles(() => ({
  arrow: {
    color: 'rgb(255, 255, 255)',
    '&:before': {
      boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3);',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(0, 0, 0)',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.3);',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    fontSize: '14px',
    fontWeight: '700',
    padding: '12px',
    maxWidth: 'none',
  },
}))(Tooltip)

const CrossSellOpportunityIcon = (props) => {
  const { opportunity } = props
  switch (opportunity) {
    case CROSS_SELL_OPPORTUNITIES.FLOOD:
      return (
        <IconFlood
          label={CROSS_SELL_OPPORTUNITIES.FLOOD}
          size="64"
          className="opportunityIcon"
        />
      )
    case CROSS_SELL_OPPORTUNITIES.EARTHQUAKE:
      return (
        <IconEarthquake
          label={CROSS_SELL_OPPORTUNITIES.EARTHQUAKE}
          size="64"
          className="opportunityIcon"
        />
      )
    case CROSS_SELL_OPPORTUNITIES.UMBRELLA:
      return (
        <IconUmbrella
          label={CROSS_SELL_OPPORTUNITIES.UMBRELLA}
          size="64"
          className="opportunityIcon"
        />
      )
    case CROSS_SELL_OPPORTUNITIES.PET:
      return (
        <IconPet
          label={CROSS_SELL_OPPORTUNITIES.PET}
          size="64"
          className="opportunityIcon"
        />
      )
    case CROSS_SELL_OPPORTUNITIES.JEWELRY:
      return (
        <IconValuables
          label={CROSS_SELL_OPPORTUNITIES.JEWELRY}
          size="64"
          className="opportunityIcon"
        />
      )
    case CROSS_SELL_OPPORTUNITIES.WATERCRAFT:
      return (
        <IconWatercraft
          label={CROSS_SELL_OPPORTUNITIES.WATERCRAFT}
          size="64"
          className="opportunityIcon"
        />
      )
    default:
      return null
  }
}

const CrossSellOpportunity = (props) => {
  const { opportunity } = props

  if (!opportunity || !OPPORTUNITY_TOOLTIPS[opportunity]) {
    return null
  }

  const toolTip = OPPORTUNITY_TOOLTIPS[opportunity]
  return (
    <GridCol sm="2" base="12" className="opportunityColumn">
      <LibertyTooltip title={toolTip} arrow placement="top">
        <div>
          <CrossSellOpportunityIcon opportunity={opportunity} />
        </div>
      </LibertyTooltip>
    </GridCol>
  )
}

export default CrossSellOpportunity
