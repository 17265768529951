const trimToEmpty = (input) => {
  return input && input.trim ? input.trim() : ''
}

/**
 * @description Takes any value and ensures that a valid array is returned.
 *  If the value is falsey (null, undefined, etc.) will return an empty array
 * @param {*} value Any value
 */
const ensureIsArray = (value) => {
  if (!value) return []
  return Array.isArray(value) ? value : [value]
}

/**
 * @param {String} code Zip code
 * @returns {String} Zip code trimmed to 5 characters only
 */
const formatPostalCode = (code) => {
  if (code.length === 5) {
    return code
  }
  return code.slice(0, 5)
}

const formatMonthOrDate = (value) => {
  return value.toString().length === 1 ? `0${value}` : value
}

const formatDOB = (dob) => {
  if (!dob) return null
  const dateOfBirth = new Date(dob)
  if (Number.isNaN(dateOfBirth)) {
    throw new Error(`Invalid DOB, must be a valid date: ${dob}`)
  }
  const month = formatMonthOrDate(dateOfBirth.getUTCMonth() + 1)
  const date = formatMonthOrDate(dateOfBirth.getUTCDate())
  const year = dateOfBirth.getUTCFullYear()
  return `${month}/${date}/${year}`
}

export { trimToEmpty, ensureIsArray, formatPostalCode, formatDOB }
