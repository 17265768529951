import axios from 'axios'
import { sendLogMessage } from './logActions'
import { getHeaders } from '../utils/header-utils'

export const CROSS_SELL_OPPORTUNITIES = {
  UMBRELLA: 'Umbrella',
  EARTHQUAKE: 'Earthquake',
  FLOOD: 'Flood',
  PET: 'Pet',
  JEWELRY: 'Jewelry',
  WATERCRAFT: 'Watercraft',
}
export const CROSS_SELL_OPPORTUNITIES_SORTED = [
  CROSS_SELL_OPPORTUNITIES.UMBRELLA,
  CROSS_SELL_OPPORTUNITIES.FLOOD,
  CROSS_SELL_OPPORTUNITIES.EARTHQUAKE,
  CROSS_SELL_OPPORTUNITIES.PET,
  CROSS_SELL_OPPORTUNITIES.JEWELRY,
  CROSS_SELL_OPPORTUNITIES.WATERCRAFT,
]

export const CHANNELS = {
  '005': 'channelCodeComparion',
  '006': 'channelCodeDirectResponseCenter',
  '002': 'channelCodePrudential',
}

export const REQUEST_QUICK_QUOTE = 'REQUEST_QUICK_QUOTE'
export const RECEIVE_QUICK_QUOTE = 'RECEIVE_QUICK_QUOTE'
export const RECEIVE_QUICK_QUOTE_ERROR = 'RECEIVE_QUICK_QUOTE_ERROR'

export const REQUEST_QUICK_QUOTE_CONFIG = 'REQUEST_QUICK_QUOTE_CONFIG'
export const RECEIVE_QUICK_QUOTE_CONFIG = 'RECEIVE_QUICK_QUOTE_CONFIG'
export const RECEIVE_QUICK_QUOTE_CONFIG_ERROR =
  'RECEIVE_QUICK_QUOTE_CONFIG_ERROR'

export const requestQuickQuote = () => {
  return {
    type: REQUEST_QUICK_QUOTE,
  }
}

export const receiveQuickQuote = (crossSellOpportunities = []) => {
  return {
    type: RECEIVE_QUICK_QUOTE,
    payload: crossSellOpportunities,
  }
}

export const receiveQuickQuoteError = () => {
  return {
    type: RECEIVE_QUICK_QUOTE_ERROR,
  }
}

export const requestQuickQuoteConfig = () => {
  return {
    type: REQUEST_QUICK_QUOTE_CONFIG,
  }
}

export const receiveQuickQuoteConfig = (configs = []) => {
  return {
    type: RECEIVE_QUICK_QUOTE_CONFIG,
    payload: configs,
  }
}

export const receiveQuickQuoteConfigError = () => {
  return {
    type: RECEIVE_QUICK_QUOTE_CONFIG_ERROR,
  }
}

export function filterCrossSellOpportunitiesByPermission(
  opportunities,
  configuration,
  channelCode
) {
  if (
    opportunities &&
    configuration.data &&
    opportunities.length > 0 &&
    configuration.data.length > 0 &&
    channelCode
  ) {
    const channel = CHANNELS[channelCode] || 'channelNone'

    return opportunities.filter((opportunity) => {
      const permissions = configuration.data.find(
        (config) =>
          config.opportunity.toLowerCase() === opportunity.toLowerCase()
      )
      return permissions ? permissions[channel] : false
    })
  }

  return []
}

/**
 * Filter out values that we have yet to support in the UI
 * @param response
 * @returns {array} Sorted (Umbrella, Flood, Earthquake, Pet)
 */
function getSupportedCrossSellOpportunities(response) {
  if (response.data && response.data.length > 0) {
    // Use the list of all possible opportunities in the desired sort order
    return CROSS_SELL_OPPORTUNITIES_SORTED.filter((opportunity) =>
      response.data.includes(opportunity)
    )
  }
  return []
}

export const fetchQuickQuote = () => async (dispatch, getState) => {
  const {
    env: { TRANSACTION_ID: transactionId },
    quote: { primaryNNumber },
    router: {
      location: {
        query: {
          agreementId,
          lob,
          zipCode,
          nNumber,
          jurisdiction,
          channelCode,
          env,
          subEnv,
        },
      },
    },
  } = getState()

  const opportunitiesUrl = `${process.env.API_BASE}/getQuickQuote/${agreementId}/${lob}/${zipCode}`
  const configsUrl = `${process.env.API_BASE}/getQuickQuote/dashboardConfiguration`
  const headers = getHeaders({ env, subEnv, transactionId })
  dispatch(requestQuickQuote())
  dispatch(requestQuickQuoteConfig())

  // Create a unique axios object, since we don't want to retry on errors
  const request = axios.create()
  const requestOpportunities = request.get(opportunitiesUrl, { headers })
  const requestConfiguration = request.get(configsUrl, { headers })

  try {
    const [quickQuoteResponse, configurationResponse] = await Promise.all([
      requestOpportunities,
      requestConfiguration,
    ])

    // Opportunities that are supported by QUI
    const eligibleOpportunities =
      getSupportedCrossSellOpportunities(quickQuoteResponse)

    // Opportunities that are allowed for current channelCode
    const crossSellOpportunitiesToDisplay =
      filterCrossSellOpportunitiesByPermission(
        eligibleOpportunities,
        configurationResponse,
        channelCode
      )

    dispatch(
      sendLogMessage('Received Cross Sell Opportunities', {
        crossSellOpportunities: quickQuoteResponse.data,
        eligibleOpportunities,
        crossSellOpportunitiesToDisplay,
        agreementId,
        lob,
        zipCode,
        jurisdiction,
        primaryNNumber,
        nNumber,
        channelCode,
      })
    )

    dispatch(receiveQuickQuoteConfig(configurationResponse.data))
    dispatch(receiveQuickQuote(crossSellOpportunitiesToDisplay))
  } catch (error) {
    dispatch(receiveQuickQuoteConfigError())
    dispatch(receiveQuickQuoteError())
    dispatch(sendLogMessage('Error fetching Cross Sell Opportunities', error))
    throw error
  }
}
