import React from 'react'
import { GridCol, GridRow, Select, SelectOption } from '@lmig/lmds-react'
import { Button } from '@lmig/lmds-react-button'
import Card from '../Elements/Card'

export const RATER_SELECTOR_HEADER = 'Pick a Comparative Rater'
export const RATER_SELECTOR_LABEL_VISUAL =
  'Please select a Comparative Rater to continue with'
export const RATER_SELECTOR_BUTTON_TEXT = 'Open Rater'

const RaterSelectorCard = (props) => {
  const {
    defaultRater,
    raters,
    showOpenButton,
    openButtonDisabled,
    updateSelectedRater,
    openSelectedRater,
  } = props

  const updateRater = (event) => {
    updateSelectedRater(event.target.value)
    console.log(`Updated rater to be ${event.target.value}`)
  }

  if (!defaultRater || !raters) {
    return null
  }
  return (
    <Card title={RATER_SELECTOR_HEADER} useSmallerHeading>
      <GridRow gutters>
        <GridCol base={8}>
          <Select
            id="raterSelection"
            onChange={updateRater}
            isRequired
            defaultValue={defaultRater}
            labelVisual={RATER_SELECTOR_LABEL_VISUAL}
          >
            {raters.map((rater) => (
              <SelectOption key={rater.name} value={rater.name}>
                {rater.displayName}
              </SelectOption>
            ))}
          </Select>
        </GridCol>
        <GridCol base={4}>
          {showOpenButton ? (
            <Button
              id="openSpecificRater"
              data-testid="openSpecificRater"
              dynamicWidth
              disabled={openButtonDisabled}
              onClick={openSelectedRater}
            >
              {RATER_SELECTOR_BUTTON_TEXT}
            </Button>
          ) : (
            <></>
          )}
        </GridCol>
      </GridRow>
    </Card>
  )
}

export default RaterSelectorCard
