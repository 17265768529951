import {
  RECEIVE_CUSTOMER_DATA,
  RECEIVE_QUOTE_DATA,
  REQUEST_CUSTOMER_DATA,
  REQUEST_QUOTE_DATA,
  SET_PRIMARY_NNUMBER,
  UPDATE_LINE_OF_BUSINESS,
} from '../../actions/quoteActions'

const initialState = {
  quoteData: null,
  customerData: null,
  isFetchingQuote: true,
  isFetchingCustomer: true,
  quoteRequestPayload: {},
  customerRequestPayload: {},
  primaryNNumber: null,
  lineOfBusiness: null,
  receivedQuoteData: false,
  receivedCustomerData: false,
}

const quoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRIMARY_NNUMBER:
      return {
        ...state,
        primaryNNumber: action.data,
      }
    case RECEIVE_QUOTE_DATA:
      return {
        ...state,
        quoteData: action.data,
        isFetchingQuote: false,
        receivedQuoteData: true,
      }
    case UPDATE_LINE_OF_BUSINESS:
      return {
        ...state,
        lineOfBusiness: action.payload,
      }
    case RECEIVE_CUSTOMER_DATA:
      return {
        ...state,
        customerData: action.data,
        isFetchingCustomer: false,
        receivedCustomerData: true,
      }
    case REQUEST_QUOTE_DATA:
      return {
        ...state,
        quoteData: {},
        isFetchingQuote: true,
        quoteRequestPayload: action.payload,
      }
    case REQUEST_CUSTOMER_DATA:
      return {
        ...state,
        customerData: {},
        isFetchingCustomer: true,
        customerRequestPayload: action.payload,
      }
    default:
      return { ...state }
  }
}

export default quoteReducer
