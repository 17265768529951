import { createSelector } from 'reselect'
import translator from '../helpers/data-translator'

const getQuote = (state) => state.quote.quoteData?.quote
const getCustomer = (state) => state.quote.customerData
const getLob = (state) => state.router.location.query.lob
const getPrimaryNNumber = (state) => state.quote.primaryNNumber

const getCustomerInfo = createSelector(
  getQuote,
  getCustomer,
  getLob,
  getPrimaryNNumber,
  (quote, customer, lob, primaryNNumber) => {
    if (quote && customer) {
      try {
        const [, data] = translator(quote, customer, lob, primaryNNumber)

        if (data) {
          const customerInfo = {
            lastName: data.customer.LastName,
            firstName: data.customer.FirstName,
            address: data.customer.CurrentAddress.AddressLine1,
            city: data.customer.CurrentAddress.City,
            state: data.customer.CurrentAddress.State,
            zip: data.customer.CurrentAddress.Zip,
          }
          return customerInfo
        }
      } catch (error) {
        // Catch any translator errors and just don't return anything
        console.log('Error computing customer info, skipping ...')
        console.log(error)
      }
    }
  }
)

export { getQuote, getCustomer, getPrimaryNNumber, getCustomerInfo }
