import { Caption, Notification } from '@lmig/lmds-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { getRepInfo } from '../../selectors/rep'
import Card from '../Elements/Card'

const RaterMessage = () => {
  const { nNumber } = useSelector(({ quote }) => ({
    nNumber: quote.primaryNNumber,
  }))

  // Get the rep's name, if available
  const repInfo = useSelector(getRepInfo)

  return (
    <Card>
      <Notification type="success">
        <Caption>Success</Caption>
        <p>
          Policy header and customer information successfully sent to Choice
          Agency Management System for compensation purposes.
        </p>
        <p>
          Applicant assigned to user {nNumber}&nbsp;
          {repInfo ? (
            <span>
              {repInfo.FirstName} {repInfo.LastName}
            </span>
          ) : null}
        </p>
      </Notification>
    </Card>
  )
}
export default RaterMessage
