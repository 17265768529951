import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Home from '../components/Home/Home'
import EndSession from '../components/EndSession/EndSession'
import Error from '../components/Error/ErrorComponent'
import NoMatch from '../components/NoMatch/NoMatch'
import { getEnvironmentVariables } from '../actions/envActions'
import { getFeatureToggles, getFeatureAccess } from '../actions/featureActions'

export default function Routes() {
  const dispatch = useDispatch()

  // Check for any pending data
  const {
    envVarsReceived,
    featureToggleRequestReceived,
    featureAccessRequestReceived,
  } = useSelector(({ env, features }) => ({
    APP_ENV: env.APP_ENV,
    envVarsReceived: env.requestReceived,
    featureToggleRequestReceived: features.featureToggleRequestReceived,
    featureAccessRequestReceived: features.featureAccessRequestReceived,
  }))
  // Check if there are any errors - we want to render if there are
  const error = useSelector((state) => state.error.error)
  if (!envVarsReceived) {
    // Dispatch the async action, this will update the state when it returns
    dispatch(getEnvironmentVariables())
    // If there is an error on the state, make sure we still render the UI
    if (error === null) {
      return null
    }
  }

  // Wait for the env vars, ensuring we have the same Transaction ID
  if (envVarsReceived) {
    if (!featureToggleRequestReceived) {
      dispatch(getFeatureToggles())
    }
    if (!featureAccessRequestReceived) {
      dispatch(getFeatureAccess())
    }
  }

  return (
    <div className="routes">
      <Switch>
        <Route path="/home" name="home" component={Home} />
        <Route path="/endsession" name="end-session" component={EndSession} />
        <Route path="/error" name="error" component={Error} />
        <Route component={NoMatch} />
        {/*
            'ATTN: THIS SHOULD ALWAYS STAY THE LAST ROUTE IN THE LIST '
            '404 file not found' sort of thing for when the routing doesn't work */}
      </Switch>
    </div>
  )
}
