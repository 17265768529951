import { REQUEST_ENV_VARS, RECEIVE_ENV_VARS } from '../../actions/envActions'

const initialState = {
  NODE_ENV: '',
  APP_ENV: '',
  TRANSACTION_ID: '',
  requestSent: false,
  requestReceived: false,
}

const envReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ENV_VARS:
      return {
        ...state,
        requestSent: true,
        requestReceived: false,
      }
    case RECEIVE_ENV_VARS:
      return {
        ...state,
        NODE_ENV: action.payload.NODE_ENV,
        APP_ENV: action.payload.APP_ENV,
        TRANSACTION_ID: action.payload.TRANSACTION_ID,
        requestSent: false,
        requestReceived: true,
      }
    default:
      return { ...state }
  }
}

export default envReducer
