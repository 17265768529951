import axios from 'axios'
import { sendLogMessage } from './logActions'
import { getHeaders } from '../utils/header-utils'

// Actions
const REQUEST_FEATURE_TOGGLES = 'REQUEST_FEATURE_TOGGLES'
const RECEIVE_FEATURE_TOGGLES = 'RECEIVE_FEATURE_TOGGLES'
const SET_FEATURE_TOGGLE = 'SET_FEATURE_TOGGLE'
const REQUEST_FEATURE_ACCESS = 'REQUEST_FEATURE_ACCESS'
const RECEIVE_FEATURE_ACCESS = 'RECEIVE_FEATURE_ACCESS'

// Action Creators
const requestFeatureToggles = () => {
  return {
    type: REQUEST_FEATURE_TOGGLES,
  }
}

const receiveFeatureToggles = (values) => {
  return {
    type: RECEIVE_FEATURE_TOGGLES,
    payload: values,
  }
}

const requestFeatureAccess = () => {
  return {
    type: REQUEST_FEATURE_ACCESS,
  }
}

const receiveFeatureAccess = (values = []) => {
  return {
    type: RECEIVE_FEATURE_ACCESS,
    eligibleFeatures: values,
  }
}

// Thunks
const getFeatureToggles = () => async (dispatch, getState) => {
  const {
    env: { TRANSACTION_ID: transactionId },
    features: { featureToggleRequestSent },
  } = getState()

  if (!featureToggleRequestSent) {
    dispatch(requestFeatureToggles())

    const url = `${process.env.API_BASE}/featureToggles`
    const request = axios.create()
    try {
      const response = await request.get(url, {
        headers: getHeaders({ transactionId }),
      })
      console.log('Feature toggles:', response.data)
      return dispatch(receiveFeatureToggles(response.data))
    } catch (error) {
      console.log('Error fetching feature toggles')
      console.log(error)
      return dispatch(receiveFeatureToggles())
    }
  }
}

const getFeatureAccess = () => async (dispatch, getState) => {
  const {
    env: { TRANSACTION_ID: transactionId },
    features: { featureAccessRequestSent },
    router: {
      location: {
        query: {
          // Provide sane defaults to make validation simpler
          // These will be used if a value is undefined
          channelCode = null,
          lob = null,
          jurisdiction = null,
          nNumber = null,
          agreementId = null,
        },
      },
    },
  } = getState()

  if (!featureAccessRequestSent) {
    dispatch(requestFeatureAccess())

    // Check for any missing URL query parameters here, since they would cause a 404 if we tried to call the server without them
    const params = { channelCode, lob, jurisdiction, nNumber, agreementId }
    const keys = Object.keys(params)
    const missing = keys.findIndex((key) => params[key] === null)
    if (missing > -1) {
      dispatch(
        sendLogMessage(
          `Error retrieving feature access, missing parameter: ${keys[missing]}`,
          params
        )
      )
      return dispatch(receiveFeatureAccess())
    }

    const url = `${process.env.API_BASE}/getFeatureAccess/${channelCode}/${lob}/${jurisdiction}/${nNumber}/${agreementId}`
    const request = axios.create()
    try {
      const response = await request.get(url, {
        headers: getHeaders({ transactionId }),
      })
      console.log('Feature access:', response.data)
      return dispatch(receiveFeatureAccess(response.data))
    } catch (error) {
      console.log('Error getting feature access')
      console.log(error)
      return dispatch(receiveFeatureAccess())
    }
  }
}

const setFeatureToggle = (feature, value) => {
  return {
    type: SET_FEATURE_TOGGLE,
    payload: {
      feature,
      value,
    },
  }
}

export {
  REQUEST_FEATURE_TOGGLES,
  RECEIVE_FEATURE_TOGGLES,
  REQUEST_FEATURE_ACCESS,
  RECEIVE_FEATURE_ACCESS,
  SET_FEATURE_TOGGLE,
  requestFeatureToggles,
  receiveFeatureToggles,
  requestFeatureAccess,
  receiveFeatureAccess,
  getFeatureToggles,
  getFeatureAccess,
  setFeatureToggle,
}
