import React from 'react'
import { Content, GridRow, GridCol, BodyText } from '@lmig/lmds-react'
import Card from '../Elements/Card'
import limuanddoug from './limuanddoug2.png'

function EndSession() {
  return (
    <Content>
      <Card
        title="Thank you for choosing Choice today."
        className="cardCentered"
      >
        <GridRow justify="center">
          <GridCol base="flex-initial">
            <BodyText type="article">
              <p>
                This session has now ended. Please close this browser
                tab/window.
              </p>
              <p>We hope to see you again soon!</p>
            </BodyText>
          </GridCol>
        </GridRow>
        <GridRow justify="center">
          <GridCol base="flex-initial">
            <img
              src={limuanddoug}
              className="choice-Logo"
              alt="Limu and Doug"
            />
          </GridCol>
        </GridRow>
      </Card>
    </Content>
  )
}

export default EndSession
