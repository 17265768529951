import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import * as datadog from './utils/datadog-utils'

import Root from './components/App/App'

// global styles
import './style.scss'

datadog.initDatadogRum()

ReactDOM.render(<Root />, document.getElementById('app'))
