import React from 'react'
import PropTypes from 'prop-types'
import { GridRow, GridCol, Heading } from '@lmig/lmds-react'
import Paper from '@material-ui/core/Paper'
import Loading from './Loading'
import './Card.scss'

export default function Card({
  children,
  isFetching,
  title,
  className,
  useSmallerHeading,
  id,
}) {
  const headingType = useSmallerHeading ? 'h4-light' : 'h3-light'
  const headingAlign = useSmallerHeading ? 'left' : 'center'
  const cardId = id
  return (
    <GridRow className={className} id={cardId} data-testid={cardId}>
      <GridCol base={12}>
        <Paper elevation={4} className="wrapper relative">
          {title && (
            <Heading type={headingType} align={headingAlign}>
              {title}
            </Heading>
          )}
          <Loading isFetching={isFetching} />
          {children}
        </Paper>
      </GridCol>
    </GridRow>
  )
}

Card.propTypes = {
  useSmallerHeading: PropTypes.bool,
  isFetching: PropTypes.bool,
  title: PropTypes.string,
}

Card.defaultProps = {
  useSmallerHeading: false,
  isFetching: false,
  title: '',
}
