import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RaterMessage from '../RaterMessage'

import './RaterRedirect.scss'

import { startRaterBridge } from '../../actions/raterActions'

const RaterRedirect = () => {
  const { sentUserToRater } = useSelector(({ rater }) => ({
    sentUserToRater: rater.sentUserToRater,
  }))
  const dispatch = useDispatch()

  useEffect(() => {
    // Only automatically launch the rater window once
    if (!sentUserToRater) {
      dispatch(startRaterBridge(window))
    }
  }, [dispatch, sentUserToRater])

  return (
    <div id="raterRedirect">
      {sentUserToRater ? (
        <>
          <RaterMessage />
        </>
      ) : (
        <p>Sending you to comparative rater...</p>
      )}
    </div>
  )
}

export default RaterRedirect
