import axios from 'axios'
import { RECEIVE_ERROR } from './errorActions'
import history from '../helpers/history'

// Actions
const REQUEST_ENV_VARS = 'REQUEST_ENV_VARS'
const RECEIVE_ENV_VARS = 'RECEIVE_ENV_VARS'

// Action Creators
const requestEnvVars = () => {
  return {
    type: REQUEST_ENV_VARS,
  }
}

const receiveEnvVars = (values) => {
  return {
    type: RECEIVE_ENV_VARS,
    payload: values,
  }
}

const receiveError = (error) => {
  // console.error("ERROR", JSON.stringify(error));
  return {
    type: RECEIVE_ERROR,
    error,
  }
}

// Thunks
const getEnvironmentVariables = () => (dispatch, getState) => {
  const {
    env: { requestSent },
  } = getState()

  if (!requestSent) {
    dispatch(requestEnvVars())
    const url = `${process.env.API_BASE}/getEnv`
    const request = axios.create()
    return request
      .get(url)
      .then((response) => {
        dispatch(receiveEnvVars(response.data))
      })
      .catch((e) => {
        dispatch(receiveError(e))
        history.push('error')
      })
  }
}

export {
  REQUEST_ENV_VARS,
  RECEIVE_ENV_VARS,
  requestEnvVars,
  receiveEnvVars,
  receiveError,
  getEnvironmentVariables,
}
