/**
 * This utility is adapted from the `ams-translator` utility used in QuoteUI server code. It's duplicated here to avoid
 * pulling in any server-only modules, such as `fs` or `path`, and to utilize ES6 syntax. Any changes or bug fixes in
 * the original code should be backported here. The OCF version of QuoteUI also needs this as a seperate module.
 */
import { formatPostalCode, formatDOB } from '../utils/data-utils'
import {
  getNamedInsured,
  getPrimaryAddress,
  getPhoneNumber,
  getEmailAddress,
  getClientNumber,
} from '../utils/quote-utils'

/**
 *
 * @param {Object} lmData This is the same data sent to quote-bind-events
 * Since we already have a function that extracts and formats all the data
 * we need, it is logical to reuse the processed data
 */
export default function translator(quote, customer, lob, nNumber) {
  const applicant = getNamedInsured(
    '1',
    quote,
    customer.householdMemberList,
    lob
  )
  const coApplicant = getNamedInsured(
    '2',
    quote,
    customer.householdMemberList,
    lob
  )

  if (!applicant) {
    throw new Error('Missing Primary Applicant!')
  }

  const primaryAddress = getPrimaryAddress(applicant)
  if (!primaryAddress) {
    throw new Error('Missing Primary Address!')
  }

  const data = {
    customer: {
      CurrentAddress: {
        AddressLine1: primaryAddress.StreetAddressDescription,
        State: primaryAddress.JurisdictionCode,
        City: primaryAddress.CityName,
        Zip: formatPostalCode(primaryAddress.ZipCode),
      },
      FirstName: applicant.FirstName,
      LastName: applicant.LastName,
    },
    quote: {
      AgreementId: quote.ID,
      AffinityPartner: getClientNumber(quote, customer),
      NNumber: nNumber,
    },
  }

  const dob = formatDOB(applicant.DateOfBirth)
  if (dob) {
    data.customer.DOB = dob
  }

  const [primaryPhone] = getPhoneNumber(applicant)
  if (primaryPhone) {
    data.customer.HomePhone = primaryPhone
  }

  const email = getEmailAddress(applicant)
  if (email) {
    data.customer.Email = email
  }

  if (coApplicant) {
    data.customer.CoApplicant = {
      FirstName: coApplicant.FirstName,
      LastName: coApplicant.LastName,
    }

    const coDOB = formatDOB(coApplicant.DateOfBirth)
    if (coDOB) {
      data.customer.CoApplicant.DOB = coDOB
    }
  }

  // Return a tuple of the ID and formatted data
  return [applicant.ID, data]
}
