const CONVERSION_CHANCE = 'conversionChance'
const CONTROL_GROUP = 'controlGroup'

const CHANNEL_CODE_FIELD = '005'
const CHANNEL_CODE_NONE = ''
const RATER_SELECTOR_JURISDICTION_CODE = 'MA'

/**
 * Compares two Channel Codes.
 * This comparison can be bypassed when the Provided Channel Code is ''.
 *
 * When Provided Channel Code is '', the User has no Channel Code.
 * When a User has no Channel Code, the implication is that it is a Dev/Test pin.
 *
 * @param providedChannelCode {string}
 * @param expectedChannelCode {string}
 * @returns {boolean}
 */
const channelCodeCheck = (providedChannelCode, expectedChannelCode) => {
  if (providedChannelCode === CHANNEL_CODE_NONE) {
    return true
  }
  return providedChannelCode === expectedChannelCode
}

/**
 * Rater Selector component is restricted to Users in the Field / Local Sales Office
 * AND to the state of Mass.
 * @param channelCode
 * @returns {boolean}
 */
const isEligibleForRaterSelector = (channelCode, jurisdictionCode) => {
  return (
    channelCodeCheck(channelCode, CHANNEL_CODE_FIELD) &&
    jurisdictionCode === RATER_SELECTOR_JURISDICTION_CODE
  )
}

// The following functions are tied to the Conversion Chance / Decision Support
// feature, which depend upon the QUI Services /featureAccess endpoint
const isEligibleForFeature = (featureName, eligibleFeatures) => {
  return eligibleFeatures && eligibleFeatures.includes(featureName)
}

const isEligibleForConversionChance = (eligibleFeatures) => {
  return isEligibleForFeature(CONVERSION_CHANCE, eligibleFeatures)
}

const isInConversionChanceControlGroup = (eligibleFeatures) => {
  return isEligibleForFeature(CONTROL_GROUP, eligibleFeatures)
}

export {
  CONVERSION_CHANCE,
  CONTROL_GROUP,
  CHANNEL_CODE_FIELD,
  CHANNEL_CODE_NONE,
  RATER_SELECTOR_JURISDICTION_CODE,
  isEligibleForConversionChance,
  isInConversionChanceControlGroup,
  isEligibleForRaterSelector,
}
