import {
  REQUEST_FEATURE_TOGGLES,
  RECEIVE_FEATURE_TOGGLES,
  REQUEST_FEATURE_ACCESS,
  RECEIVE_FEATURE_ACCESS,
  SET_FEATURE_TOGGLE,
} from '../../actions/featureActions'

const initialState = {
  features: {
    decisionSupport: false,
    quickQuote: false,
    raterSelector: false,
  },
  featureToggleRequestSent: false,
  featureToggleRequestReceived: false,
  eligibleFeatures: [],
  featureAccessRequestSent: false,
  featureAccessRequestReceived: false,
}

const featureReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FEATURE_TOGGLES:
      return {
        ...state,
        featureToggleRequestSent: true,
        featureToggleRequestReceived: false,
      }
    case RECEIVE_FEATURE_TOGGLES: {
      const features = action.payload || { ...state.features }
      return {
        ...state,
        features,
        featureToggleRequestReceived: true,
      }
    }
    case REQUEST_FEATURE_ACCESS: {
      return {
        ...state,
        featureAccessRequestSent: true,
        featureAccessRequestReceived: false,
      }
    }
    case RECEIVE_FEATURE_ACCESS: {
      return {
        ...state,
        eligibleFeatures: action.eligibleFeatures,
        featureAccessRequestReceived: true,
      }
    }
    case SET_FEATURE_TOGGLE: {
      const { feature, value } = action.payload
      console.log(`Setting ${feature} to ${value}`)

      return {
        ...state,
        [feature]: value,
      }
    }
    default:
      return { ...state }
  }
}

export default featureReducer
