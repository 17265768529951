import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import axios from 'axios'

import history from '../helpers/history'
import createRootReducer from './reducers/index'

const middlewares = [routerMiddleware(history), thunk]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// Set up Axios defaults here, since Redux actions are where it is used

axios.defaults.headers.common['Cache-Control'] = 'no-cache'
axios.defaults.headers.common.Pragma = 'no-cache'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...middlewares))
)
