import axios from 'axios'
import { ConversionChanceStateNames } from '../components/DecisionSupport/ConversionChance'
import { sendLogMessage } from './logActions'
import { getHeaders } from '../utils/header-utils'

export const REQUEST_CONVERSION_CHANCE = 'REQUEST_CONVERSION_CHANCE'
export const RECEIVE_CONVERSION_CHANCE = 'RECEIVE_CONVERSION_CHANCE'
export const LOG_RESULT_MESSAGE_SENT =
  'CONVERSION_CHANCE_LOG_RESULT_MESSAGE_SENT'
export const LOG_CONTROL_OR_TEST_MESSAGE_SENT =
  'CONVERSION_CHANCE_LOG_CONTROL_OR_TEST_MESSAGE_SENT'

export const requestConversionChance = () => {
  return {
    type: REQUEST_CONVERSION_CHANCE,
    conversionChanceState: ConversionChanceStateNames.LOADING,
  }
}

function determineConversionChance(isCandidateForChoice, reason) {
  // Default to Choice Preferred
  let conversionChanceState = ConversionChanceStateNames.CHOICE
  // If customer is not a good candidate, set to Liberty Preferred
  if (!isCandidateForChoice) {
    conversionChanceState = ConversionChanceStateNames.LIBERTY
  } else if (reason && reason.search(/underwriting reject/i) >= 0) {
    // Check if this is an underwriting reject (still considered Choice Preferred, with a different message)
    conversionChanceState = ConversionChanceStateNames.UNDERWRITING_REJECT
  }
  return conversionChanceState
}

export const receiveConversionChance = (conversionChanceState, reason) => {
  return {
    type: RECEIVE_CONVERSION_CHANCE,
    conversionChanceState,
    reason,
  }
}

export const receiveConversionChanceError = (errorMessage = null) => {
  return {
    type: RECEIVE_CONVERSION_CHANCE,
    conversionChanceState: ConversionChanceStateNames.ERROR,
    reason: errorMessage,
  }
}

export const fetchConversionChance = () => async (dispatch, getState) => {
  const {
    env: { TRANSACTION_ID: transactionId },
    router: {
      location: {
        query: { agreementId, lob, customerId, nNumber, env, subEnv },
      },
    },
  } = getState()

  const url = `${process.env.API_BASE}/getConversionChance/${agreementId}/${customerId}/${lob}/${nNumber}`
  const headers = getHeaders({ env, subEnv, transactionId })

  dispatch(requestConversionChance())

  // Create a unique axios object, since we don't want to retry on errors
  const request = axios.create()

  try {
    const response = await request.get(url, {
      headers,
    })

    const conversionChanceState = determineConversionChance(
      response.data.isCandidateForChoice,
      response.data.reasonCode
    )
    dispatch(sendLogMessage('Received Conversion Chance', response.data))
    dispatch(
      receiveConversionChance(conversionChanceState, response.data.reasonCode)
    )
  } catch (error) {
    dispatch(sendLogMessage('Error fetching Conversion Chance', error))
    throw error
  }
}

const logDecisionSupport = (dispatch, getState) => {
  const {
    decisionSupport: { isControlGroup, conversionChanceState, reason },
    quote: { primaryNNumber },
    router: {
      location: {
        query: { agreementId, lob, nNumber, channelCode, jurisdiction },
      },
    },
  } = getState()

  dispatch(
    sendLogMessage(`Decision Support: ${conversionChanceState}`, {
      agreementId,
      channelCode,
      jurisdiction,
      lob,
      nNumber,
      primaryNNumber,
      isControlGroup,
      conversionChance: conversionChanceState,
      reason,
    })
  )
}

export const logDecisionSupportControlOrTestGroup =
  () => async (dispatch, getState) => {
    const {
      decisionSupport: {
        logControlOrTestMessageSent,
        conversionChanceState,
        isEligible,
        isControlGroup,
      },
    } = getState()

    // Don't log LOADING state
    if (
      !logControlOrTestMessageSent &&
      conversionChanceState !== ConversionChanceStateNames.LOADING
    ) {
      // Only log if the user is in the control or test group
      // This avoids logging users in invalid jurisdictions
      if (isEligible || isControlGroup) {
        logDecisionSupport(dispatch, getState)
        dispatch({
          type: LOG_CONTROL_OR_TEST_MESSAGE_SENT,
        })
      }
    }
  }

export const logDecisionSupportResult = () => async (dispatch, getState) => {
  const {
    decisionSupport: { logResultMessageSent },
  } = getState()

  if (!logResultMessageSent) {
    logDecisionSupport(dispatch, getState)
    dispatch({
      type: LOG_RESULT_MESSAGE_SENT,
    })
  }
}
