import { Content } from '@lmig/lmds-react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './Home.scss'

import { triggerError } from '../../actions/errorActions'
import { sendLogMessage } from '../../actions/logActions'
import { fetchCustomerData, fetchQuoteData } from '../../actions/quoteActions'
import { fetchRaterUrl } from '../../actions/raterActions'

import { getCanFetchRaterUrl, getRaterUrlIsReady } from '../../selectors/rater'
import CcpaMessage from '../CcpaMessage/CcpaMessage'
import DecisionSupport from '../DecisionSupport/DecisionSupport'
import Card from '../Elements/Card'
import QuickQuote from '../QuickQuote/QuickQuote'
import RaterRedirect from '../RaterRedirect/RaterRedirect'
import RaterSelector from '../RaterSelector/RaterSelector'

export default function Home() {
  const dispatch = useDispatch()

  const { sentUserToRater, ccpaVisible, ccpaAccepted, message } = useSelector(
    ({ rater }) => ({
      sentUserToRater: rater.sentUserToRater,
      ccpaVisible: rater.ccpaVisible,
      ccpaAccepted: rater.ccpaAccepted,
      message: rater.message,
    })
  )

  const canFetchRaterUrl = useSelector(getCanFetchRaterUrl)
  const hasRaterUrl = useSelector(getRaterUrlIsReady)

  let view

  useEffect(() => {
    // Fetch the quote and customer data, then the reference key
    async function fetchData() {
      console.log('Fetching quote and customer data')
      try {
        dispatch(fetchCustomerData())
        // Wait for the quote data in order to get the primary NNumber
        await dispatch(fetchQuoteData())
      } catch (error) {
        dispatch(triggerError(error))
      }
    }
    // Call the above async function, useEffect functions are synchronous by design
    fetchData()
    dispatch(
      sendLogMessage(`Logging page viewport`, {
        // On more modern browsers this is apparently the 'proper' way to get the full picture of the page size
        // This is undefined on IE11
        viewport: window.visualViewport,
        // On IE11 this should correspond to the zoom percentage. On other browsers it doesn't
        devicePixelRatio: window.devicePixelRatio,
        // Get all the relevant page size numbers for IE11 and other browsers
        pageSize: {
          inner: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
          outer: {
            width: window.outerWidth,
            height: window.outerHeight,
          },
        },
      })
    )
  }, [dispatch])

  useEffect(() => {
    async function getRaterInfo() {
      try {
        // TODO: Don't fetch rater info until after CCPA is accepted
        console.log('Fetching Comparative Rater URL')
        await dispatch(fetchRaterUrl())
      } catch (error) {
        dispatch(triggerError(error))
      }
    }

    if (canFetchRaterUrl) {
      getRaterInfo()
    }
  }, [canFetchRaterUrl, dispatch])

  // Set the view to be rendered
  if (ccpaVisible) {
    // Initially show the CCPA Message
    view = <CcpaMessage />
  } else if (!ccpaVisible && !hasRaterUrl && !sentUserToRater) {
    // CCPA has been accepted, waiting on rater URL, user has not opened a rater window yet
    view = <Card isFetching title={message} />
  } else if (ccpaAccepted && (hasRaterUrl || sentUserToRater)) {
    // CCPA has been accepted, not waiting for rater URL OR user has already opened a rater window so don't show the spinner again
    view = <RaterRedirect />
  }

  return (
    <Content>
      <DecisionSupport />
      <QuickQuote />
      {view}
      <RaterSelector />
    </Content>
  )
}

Home.defaultProps = {
  message: 'Gathering data and opening comparative rater',
}
