import { createSelector } from 'reselect'
import { isEligibleForRaterSelector } from '../utils/feature-utils'

const getChannelCode = (state) => state?.router?.location?.query?.channelCode
const getJurisdictionCode = (state) =>
  state?.router?.location?.query?.jurisdiction

/**
 * Check whether the rater selector is eligible to be displayed based on
 *   channel and jurisdiction.
 *
 * @return {boolean}
 */
const selectDisplayRaterSelector = createSelector(
  getChannelCode,
  getJurisdictionCode,
  (channelCode, jurisdictionCode) => {
    return isEligibleForRaterSelector(channelCode, jurisdictionCode)
  }
)

export { getChannelCode, getJurisdictionCode, selectDisplayRaterSelector }
